module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icons":[{"src":"/favicon.ico","type":"image/x-icon","sizes":"32x32"},{"src":"/icon-192.png","type":"image/png","sizes":"192x192"},{"src":"/icon-512.png","type":"image/png","sizes":"512x512"},{"src":"/maskable-192.png","type":"image/png","sizes":"192x192","purpose":"maskable"},{"src":"/maskable-512.png","type":"image/png","sizes":"512x512","purpose":"maskable"}],"name":"DOSUGCZ-RU.RU","short_name":"DOSUGCZ-RU.RU","orientation":"portrait","display":"standalone","start_url":"/","description":"Application «Dosugcz-Ru.Ru» web site","background_color":"#ffffff","theme_color":"#ffffff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
